<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" md="6">
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <v-row>
              <h4
                class="h4 div-info-title"
                style="color: var(--primary)"
                v-if="totalAllocated"
              >
                {{ format_to_currency(totalAllocated) }}
              </h4>
              <v-progress-circular
                v-else
                indeterminate
                size="30"
                color="primary"
              >
              </v-progress-circular>
            </v-row>
            <v-row>
              <h5 class="h5 div-info-title">{{ $t("total_allocated") }}</h5>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <v-row>
              <h4
                v-if="!accessInfoLoading"
                class="h4 div-info-title"
                style="color: var(--primary)"
              >
                {{ totalUserCount }}
              </h4>
              <v-progress-circular
                v-else
                indeterminate
                size="30"
                color="primary"
              >
              </v-progress-circular>
            </v-row>
            <v-row>
              <h5 class="h5 div-info-title">{{ $tc("user", 2) }}</h5>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" style="padding-top: 0">
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <div>
              <v-row>
                <h4
                  v-if="!lastDayInfoLoading"
                  class="h4 div-info-title"
                  style="color: var(--primary)"
                >
                  {{ lastDayAccessCount }}
                </h4>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="30"
                  color="primary"
                >
                </v-progress-circular>
              </v-row>
              <v-row>
                <h5 class="h5 div-info-title">{{ $t("last_day_accesses") }}</h5>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" style="padding-top: 0">
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <div>
              <v-row>
                <h4
                  v-if="!lastTenDaysInfoLoading"
                  class="h4 div-info-title"
                  style="color: var(--primary)"
                >
                  {{ lastTenDaysAccessCount }}
                </h4>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="30"
                  color="primary"
                >
                </v-progress-circular>
              </v-row>
              <v-row>
                <h5 class="h5 div-info-title">{{ $t("ten_days_accesses") }}</h5>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" style="padding-top: 0">
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <div>
              <v-row>
                <h4
                  v-if="!totalAcessesInfoLoading"
                  class="h4 div-info-title"
                  style="color: var(--primary)"
                >
                  {{ totalAccessCount }}
                </h4>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="30"
                  color="primary"
                >
                </v-progress-circular>
              </v-row>
              <v-row>
                <h5 class="h5 div-info-title">{{ $t("accesses") }}</h5>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-container
      class="white rounded"
      tag="section"
      fluid
      v-if="investments_chart.datasets.length > 0"
    >
      <h4 class="h4 mb-2">{{ $tc("chart", 2) }}</h4>
      <v-row justify="center" v-if="chartLoading">
        <v-progress-circular indeterminate size="30" color="primary">
        </v-progress-circular>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="12">
          <line-chart :chartData="investments_chart" :options="options" />
          <!-- <chartist
            :data="investments_chart.data"
            :options="investments_chart.options"
            type="Line"
            style="max-height: 300px"
          /> -->
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="white mt-4 rounded"
      tag="section"
      fluid
      v-if="partners.length > 0 || partnersLoading"
    >
      <v-row>
        <h4 class="h5 mt-3">{{ $tc("partner", 2) }}</h4>
      </v-row>

      <v-row justify="center" v-if="partnersLoading">
        <v-progress-circular indeterminate size="30" color="primary">
        </v-progress-circular>
      </v-row>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("email") }}</th>
            <th class="text-center">{{ $tc("user", 2) }}</th>
            <th class="text-center">{{ $t("allocations_pending") }}</th>
            <th class="text-center">{{ $tc("investment", 2) }}</th>
            <th class="text-center">{{ $t("total_allocated") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(partner, index) in partners" :key="index">
            <td class="text-center">{{ show_name(partner) }}</td>
            <td class="text-center">{{ partner.Email }}</td>
            <td class="text-center">
              {{ partner.TotalUsers != 0 ? partner.TotalUsers : "-" }}
            </td>
            <td class="text-center">
              {{
                partner.TotalInvested != 0
                  ? format_to_currency(partner.TotalInvested)
                  : "-"
              }}
            </td>
            <td class="text-center">
              {{
                partner.TotalInvestments != 0 ? partner.TotalInvestments : "-"
              }}
            </td>
            <td class="text-center">
              {{
                partner.TotalAllocated != 0
                  ? format_to_currency(partner.TotalAllocated)
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";

export default {
  name: "AdminDashboard",
  components: {
    LineChart: () => import("@/components/charts/LineChart"),
  },
  data: () => ({
    value: "",
    apiService: new ApiService(),
    total_value: 0,
    users: [],
    indices: [],
    legend: [],
    investments_data_table: [],
    partners: [],
    totalUserCount: null,
    lastDayAccessCount: null,
    lastTenDaysAccessCount: null,
    totalAccessCount: null,
    totalInvested: null,
    totalAllocated: null,
    chartData: [],
    companies: [],
    accessInfoLoading: false,
    lastDayInfoLoading: false,
    lastTenDaysInfoLoading: false,
    totalAcessesInfoLoading: false,
    chartLoading: false,
    partnersLoading: false,
    colors: [
      "#01d281",
      "#7733FF",
      "#197DFF",
      "#ff9900",
      "#ffff00",
      "#ff99ff",
      "#006622",
      "#ff0000",
      "#000000",
    ],
    investments_chart: {
      labels: [],
      datasets: [],
    },
  }),

  async created() {
    this.accessInfoLoading = true;
    this.get_last_day_access_info();
    this.get_last_ten_days_access_info();
    this.get_total_accesses_info();
    this.get_chart_info();
    this.get_partners();
  },
  computed: {
    options() {
      return {
        // legend: {
        //   display: false,
        // },
        elements: {
          line: {
            tension: 0,
          },
        },
        responsive: false,
        maintainAspectRatios: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) => {
                  return `R$ ${value
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return parseFloat(tooltipItems.yLabel)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          },
        },
      };
    },
  },
  methods: {
    async get_last_day_access_info() {
      this.lastDayInfoLoading = true;
      var date = moment().add(-1, "days").format("YYYY-MM-DDT00:00:00");
      this.lastDayAccessCount = await this.get_access_info(date);
      this.lastDayInfoLoading = false;
    },
    async get_last_ten_days_access_info() {
      this.lastTenDaysInfoLoading = true;
      var date = moment().add(-10, "days").format("YYYY-MM-DDT00:00:00");
      this.lastTenDaysAccessCount = await this.get_access_info(date);
      this.lastTenDaysInfoLoading = false;
    },
    async get_total_accesses_info() {
      this.totalAcessesInfoLoading = true;
      var date = moment("01/01/2020", "MM-DD-YYYY").format(
        "YYYY-MM-DDT00:00:00"
      );
      this.totalAccessCount = await this.get_access_info(date);
      this.totalAcessesInfoLoading = false;
    },
    async get_access_info(date) {
      var totalAccesses;
      await this.apiService
        .postRequest("partner/dashboard-access-info", { startDate: date })
        .then((resp) => {
          var model = JSON.parse(resp);
          this.totalUserCount = model.TotalUserCount;
          totalAccesses = model.TotalAccessCount;
          if (this.accessInfoLoading) {
            this.accessInfoLoading = false;
          }
        })
        .catch((error) => {});
      return totalAccesses;
    },
    async get_chart_info() {
      this.chartLoading = true;
      await this.apiService
        .getRequest("partner/dashboard-admin-chart")
        .then((resp) => {
          var model = JSON.parse(resp);
          this.totalAllocated = model.TotalAllocated;
          this.chartData = model.ChartData;
          this.companies = model.Companies;
          this.fill_chart();
        })
        .catch((error) => {});
      this.chartLoading = false;
    },
    async get_partners() {
      this.partnersLoading = true;
      await this.apiService
        .getRequest("partner/dashboard-partners")
        .then((resp) => {
          var model = JSON.parse(resp.message);
          this.partners = model.Partners;
          this.totalInvested = model.TotalInvested;
        })
        .catch((error) => {});
      this.partnersLoading = false;
    },
    show_name: function (user) {
      if (user.Name === null) {
        return "";
      } else {
        return user.Name.concat(" ").concat(user.LastName);
      }
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    format_data: function (value) {
      if (value == "0001-01-01T00:00:00") return "-";
      return moment(value).format("HH:mm DD/MM/YYYY ");
    },

    fill_chart() {
      var series, i, date, day, month, year, history_value, investments_length;
      investments_length = this.companies.length;

      try {
        var colorCount = 0;
        for (i = 0; i < investments_length; i++) {
          this.investments_chart.datasets.push({
            label: this.companies[i].Name,
            backgroundColor: this.colors[colorCount],
            fill: false,
            borderColor: this.colors[colorCount],
            data: [],
          });
          colorCount++;
        }
        this.chartData.forEach((s) => {
          for (i = 0; i < investments_length; i++) {
            history_value = s.filter(
              (x) => x.CompanyId == this.companies[i].CompanyId
            );
            if (history_value.length > 0) {
              history_value = history_value[0];
              if (history_value.Value) {
                this.investments_chart.datasets[i].data.push(
                  history_value.Value
                );
              } else {
                this.investments_chart.datasets[i].data.push(null);
              }
            } else {
              this.investments_chart.datasets[i].data.push(null);
            }
          }
          this.investments_chart.labels.push(
            moment(s[0].Date).format("DD/MM/YYYY")
          );
        });
      } catch (err) {}
      this.loading = false;
    },
  },
};
</script>
